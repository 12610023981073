import React, { useState } from 'react'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { ApiClient } from '../services/api/ApiClient'

import { Option } from 'react-bootstrap-typeahead/types/types'
import { AutoComplete } from '../types/common/AutoComplete'
type Props = {
  onSelect: (patient_id: number | "") => void
}
export const PatientSelector: React.FC<Props> = ({ onSelect }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState<AutoComplete[]>([])

  const search = (query: string) => {
    ApiClient.patients.autocomplete(query).then((response) => {
      setOptions(response)
    })
  }

  const handleChange = (e: AutoComplete[]) => {
    let x = e[0]
    onSelect(e[0]?.id!)
  }
  return (
    <AsyncTypeahead
      id='patient-selector'
      onSearch={search}
      options={options}
      isLoading={isLoading}
      onChange={(selected) => handleChange(selected as AutoComplete[])}
    />
  )

}